body,
html {
  height: 100%;
  margin: 0;
}
.nav-bar {
  padding-top: 20px;
  padding-left: 15px;
  padding-bottom: 20px;
  color: #ffffff;
  background-color: #2d2d2d;
  width: 100%;
  position: fixed;
  z-index: 1;
}

.content {
  width: 100%;
}

@media only screen and (min-width: 1000px) {
  .content {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}

.super{
  position:'absolute'  ;
   width: '100%' ;
}
.image-background {
  background-repeat: no-repeat;
  background-attachment: fixed;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 0px 2000px inset;
  background-size: cover;
  height: 100%;

}

.custom-card {
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f5f6f7;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 6px;
  padding-left: 6px;
  padding-right: 6px;
}

.center {
  padding-left: auto;
  padding-right: auto;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}





.navBar {
  height: 46px;
  width: 100%;
  background-color: #2d2d2d;
  color: #ffffff;
  padding: 6px 1px;
  font-size: 16px;
  position: fixed;
  z-index: 10;
}

body {
  background-color: #f3f5f6;
}
.body {
  padding-left: 5px;
  padding-right: 5px;
}

.highlight {
  height: auto;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;

  background-repeat: no-repeat;
  color: #ffffff;
  background-position: center;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 0px 2000px inset;
  text-align: center;
}

.blankHighlight {
  height: auto;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 5px;
  padding-right: 5px;
  color: #2d2d2d;
  background-color: #ffffff;
  text-align: center;
}

.pad2 {
  padding-left: "15px";
  padding-right: "15px";
}

.loader {
  position: absolute;
  left: 50%;
  width: 100%;

  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loader img {
  width: 200px;
  height: 200px;
}

#main {
  padding-bottom: 7px;
}

.auto-complete {
  width: 100%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.auto-complete .container {
  border: 1px solid #cccccc;
  padding: 2px;
  border-radius: 5px;
  margin-top: 2px;
  overflow-y: scroll;
  height: auto;
  max-height: 200px;
  background-color: #cccccc;
  display: none;
}

.auto-complete .container .elements {
  border-radius: 2px;
  padding: 8px;

  margin-top: 2px;
  background-color: #ffffff;
  width: 100%;
  display: inline-flex;
}

.auto-complete .container .elements img {
  height: 50px;
  width: 50px;
}

.auto-complete .container .not-found-elements {
  text-align: center;
  padding: 5px;
  background-color: #ffffff;
  color: rgb(168, 168, 168);
}

.auto-complete .container .elements .grid-container {
  height: 100%;
  margin-left: 6px;
}

.auto-complete .container .elements .grid-container .item-name {
  font-size: 15px;
  font-weight: 500;
}
.auto-complete .container .elements .grid-container .item-category {
  font-size: 12px;
}

.auto-complete .container .elements .grid-container .grid {
  display: grid;

  grid-template-rows: auto auto;
}

.item:hover {
  transform: scale(1.1);
  z-index: 1;
  background-color: #e8e8e8;
}

.auto-complete .container .elements:hover {
  background-color: #e8e8e8;
}

.input-boxes {
    padding: 15px 8px;
}


.grid-items {
  
  padding:5px;
  margin: 5px;

}
.grid-items img {
          width: 60%;
          margin-top:8px;
          margin-left:auto;
          margin-right:auto;
          max-width: 120px;
}
.grid-items h5{
  margin-left:auto;
  margin-right:auto;
  color: #606c76;
  margin-top:6px;
  margin-bottom:6px;
}
.grid-items:hover{
  transform: scale(1.1); 
 
  z-index:10000; 
}
 

.grid-container{
      text-align: center;
      display: inline-flex;
      border: 2px dashed black;
      border-radius: 5px;
      border-color: #808080;

}
.grid-container .grid-item {
      display: grid;
      grid-template-areas: 
      'header'
      'body';
      padding: 0px 15px;
      margin: 0px auto;
}

.grid-container .grid-item .grid-header {
        grid-area: 'header';
}
.grid-container .grid-item .grid-body {
        grid-area: 'body' ;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}